import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const ReactMarkdown = require('react-markdown')


const SectionOuterContainer = styled.section.attrs({
  className: 'apply-even-odd-style py-16',
})`
  &:nth-child(even) {
    background: #f4f4f4 !important;
  }
  p {
    margin-bottom: 1em;
  }
  h3 {
    font-size: 1.15em;
    font-weight: 500;
  }
  .react-tabs__tab {
    padding:1em .5em;
    &:not(.react-tabs__tab--selected ) {
      color: #222;
    }
  }
  .react-tabs__tab--selected {
    border-style: solid;
    border-right-width: 3px;
    margin-right: -1px;
  }
  
`
const InnerContainer = styled.div.attrs({
  className: 'container mx-auto px-6 flex flex-wrap items-center',
})``



const Flex = styled.div.attrs({
  className: 'flex flex-wrap md:flex-no-wrap',
})``

const TabsContent = styled.div.attrs({
  className: 'w-full md:w-3/4',
})``


const ContentTabbed = ({
  items
}) => {
  return (
<SectionOuterContainer>
  <InnerContainer>
    <Tabs>

   <Flex>
    <TabList className="w-full md:w-1/4 md:border-r md:border-solid md:border-gray-200 md:mr-12">
      {items.map((item,i)=>(<Tab key={i} className="cursor-pointer outline-none react-tabs__tab pointer border-blue-500 text-blue-500 font-semibold">{item.title}</Tab>))}
    </TabList>
      <TabsContent>
        {items.map((item,i)=>(<TabPanel key={i}><h2 className="text-2xl md:text-3xl font-semibold leading-tight text-blue-500 mb-4">{item.title}</h2><ReactMarkdown source={item.body.body} /></TabPanel>))}
      </TabsContent>
    
    </Flex>
  </Tabs>
  </InnerContainer>
  </SectionOuterContainer>
  )
}

ContentTabbed.propTypes = {
  items: PropTypes.array,
}

ContentTabbed.defaultProps = {
  items: null
}

export default ContentTabbed
