import React from 'react'

import Img from 'gatsby-image'
import { Link } from 'gatsby'

export const BlogPostsOrRelated = ({ title, data }) => {
  return (
    data.length > 0 && (
      <div className="container mx-auto px-3 py-8">
        <h2 className="font-bold mb-3 md:mb-6 text-xl md:text-3xl">{title}</h2>
        <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
          {data.map((item, i) => (
            <Link
              key={i}
              to={`/blog/${item.node.slug}`}
              className="bg-white rounded-lg shadow hover:shadow-lg"
            >
              {item.node.heroImage && (
                <Img
                  fluid={item.node.heroImage.fluid}
                  style={{ height: '150px' }}
                  alt={item.node.title}
                  className="rounded-t-lg"
                />
              )}
              <div className="p-3 py-4">
                <span className="text-gray-700 text-base">
                  {item.node.publishDate}
                </span>
                <span className=" font-semibold leading-tight text-gray-900 block">
                  {item.node.title}{' '}
                </span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    )
  )
}
