import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import ContentSection from '../components/content-section'
import ContentTabbed from '../components/content-tabbed'
import PredefinedComponentController from '../components/predefined-component-controller'
import GridContentSection from '../components/grid-content-section'
import { BlogPostsOrRelated } from '../components/BlogPostsOrRelated'


export default function PageTemplate({
  data,
  location,
  pageContext: { latestBlogs },
}) {
  const { metaTitle, metaDescription, pageContent } = data.contentfulPage
  const {
    site: { siteMetadata },
  } = data
  return (
    <Layout location={location}>
      <SEO title={metaTitle} description={metaDescription} />
      {pageContent.map((item, i) => {
        if (
          item.__typename === 'ContentfulContentSection' ||
          item.__typename === 'ContentfulContentWSidebar'
        ) {
          let showRightSidebar = item.__typename === 'ContentfulContentWSidebar'
          let sidebarArray = item && item.sidebar
          let renderCopyAsHtml = showRightSidebar

          //if show right sidebar, assuming left content section should render as html

          return (
            <ContentSection
              key={i}
              showRightSidebar={showRightSidebar}
              renderCopyAsHtml={renderCopyAsHtml}
              sidebarArray={sidebarArray}
              topHeading={item.topHeading}
              topSubheading={item.topSubheading}
              showStars={item.showStars}
              heading={item.heading}
              addImageContainer={item.addImageContainer}
              copy={item.copy.copy}
              image={item.image}
              layout={item.layout}
              buttonText={item.buttonText}
              buttonLink={item.buttonLink}
              buttonHelperText={item.buttonHelperText}
              cssClass={item.cssClass}
            />
          )
        }
        if (item.__typename === 'ContentfulPredefinedComponents') {
          return (
            <PredefinedComponentController
              key={i}
              componentID={item.contentful_id}
              moduleId={item.moduleId}
            />
          )
        }
        if (item.__typename === 'ContentfulTabbedContent') {
          return <ContentTabbed key={i} items={item.items} />
        }
        if (item.__typename === 'ContentfulGridContentSection') {
          return (
            <GridContentSection
              key={i}
              checkContentForCityTag={item.checkContentForCityTag}
              moduleId={item.moduleId}
              componentID={item.contentful_id}
              title={item.title}
              heading={item.heading}
              copy={item.copy && item.copy.copy}
              layout={item.layout}
              mobileItemsPerRow={item.mobileItemsPerRow}
              desktopItemsPerRow={item.desktopItemsPerRow}
              items={item.items}
            />
          )
        }
        return null
      })}
      <BlogPostsOrRelated title={'From Our Blog'} data={latestBlogs} />
      
    </Layout>
  )
}

export const pageQuery = graphql`
  query pageQuery($slug: String!) {
    site {
      siteMetadata {
        siteUrl
        title
        companyInfo {
          phone
          email
          addressLine1
          city
          state
          zip
        }
        socials {
          facebook
          instagram
          google
          dribble
        }
        description
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      id
      slug
      title
      metaTitle
      metaDescription
      pageContent {
        __typename
        ... on ContentfulContentWSidebar {
          id
          copy {
            copy
          }
        }
        ... on ContentfulGridContentSection {
          checkContentForCityTag
          id
          title
          contentful_id
          title
          subheading
          heading
          copy {
            copy
          }
          layout
          mobileItemsPerRow
          desktopItemsPerRow
          items {
            __typename
            ... on ContentfulGridItem {
              id
              title
              copy {
                copy
              }
              layout
              svgIcon {
                svgIcon
              }
            }
            ... on ContentfulPricingPackage {
              title
              notes
              whatsIncluded {
                whatsIncluded
              }
              buttonLink
              buttonText
              startingAtPrice
              strikeOutPrice
              svgImage {
                svgImage
              }
              image {
                fluid(maxWidth: 350) {
                  srcSet
                  srcWebp
                  srcSetWebp
                  ...GatsbyContentfulFluid
                }
              }
              description {
                description
              }
              contentful_id
              id
            }
          }
        }
        ... on ContentfulContentSection {
          id
          title
          addImageContainer
          heading
          copy {
            copy
          }
          title
          buttonText
          buttonLink
          buttonHelperText
          image {
            id
            file {
              url
              contentType
            }
            fluid(maxWidth: 908) {
              ...GatsbyContentfulFluid
            }
          }
          cssClass
          layout
          theme
          renderCopyAsHtml
        }
        ... on ContentfulPredefinedComponents {
          id
          title
          contentful_id
          moduleId
        }
      }
    }
  }
`

//line 94, tabbed content
// ... on ContentfulTabbedContent {
//   id
//   title
//   items {
//     renderBodyAsHtml
//     title
//     body {
//       body
//     }
//   }
// }

// float-right md:float-right md:p-0 md:w-64 px-8 w-full
